import React, { useState, useEffect } from 'react';
import ConfigProvider from 'antd/lib/config-provider';
import { Locale } from 'antd/lib/locale-provider';
import IntlPolyfill from 'intl';
import Head from 'next/head';
import Router from 'next/router';
import { AppProps } from 'next/app';
import intl from 'react-intl-universal';
import enUS from 'antd/lib/locale-provider/en_US';
import deDe from 'antd/lib/locale-provider/de_DE';
import includes from 'lodash/includes';

import ServicesProvider from '@packages/services';
import Cookie from '@packages/cookie';
import Logger from '@packages/logger/core/coreLogger';

import Loader from '@components/common/loaderComponent/Loader';
import ErrorBoundary from '@components/ErrorBoundary';
import GlobalStyles from '@components/GlobalStyles.styled';

import getLocale from '@helpers/getLocale';
import handleDocumentOnListener from '@helpers/handleDocumentOnListener';
import numeral from '@helpers/numeral';
import setUserTimeZone from '@helpers/setUserTimeZone';

import { UserProvider } from '@providers/user';
import setUser from '@providers/user/actions/setUser';
import { NotificationsProvider } from '@providers/notifications';
import { ApplicationProvider } from '@providers/applicationContext';

import { IUser } from '../server/interfaces';

import 'antd/dist/antd.css';
import '../public/index.css';
import '../public/animations.css';
import '../public/fonts/roboto.css';

global.Intl = IntlPolyfill;
require('intl/locale-data/jsonp/en.js');
/* eslint-disable */
const locales = {
  'en-us': require('../locales/en-US.json'),
  'de-de': require('../locales/de-DE.json'),
};
/* eslint-enable */

const App = ({
  Component,
  pageProps,
  props,
}: AppProps & { props: { user: IUser } }): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const loadLocaleData = async (): Promise<void> => {
    try {
      await intl.init({
        currentLocale: getLocale(),
        locales,
      });
      setLoaded(true);
    } catch (e) {
      new Logger({ logToConsole: true }).log(e.message);
    }
  };

  useEffect(() => {
    const windowHeight = window.innerHeight;
    loadLocaleData();
    setUserTimeZone();
    setUser(props?.user);

    const handelOnFocus = (event): void => {
      handleDocumentOnListener(event, windowHeight);
    };

    if (
      includes(navigator.userAgent, 'iPhone') &&
      includes(navigator.userAgent, 'Safari') &&
      !includes(navigator.userAgent, 'CriOS')
    ) {
      window.addEventListener('focusin', handelOnFocus);
    }

    if (new Cookie().get('lang') === 'en-us') {
      numeral.locale('en');
    } else {
      numeral.locale('de');
    }

    return () => {
      window.removeEventListener('focusin', handelOnFocus);
    };
  }, []);

  Router.events.on('routeChangeStart', (): void => {
    setPageLoading(true);
  });
  Router.events.on('routeChangeComplete', (): void => {
    setPageLoading(false);
  });
  Router.events.on('routeChangeError', (): void => {
    setPageLoading(false);
  });

  const currentLocale = (): Locale => {
    if (getLocale() === 'de-de') {
      return deDe;
    }
    return enUS;
  };

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>Aircash Partner Portal</title>
      </Head>
      <ConfigProvider locale={currentLocale()}>
        <ServicesProvider>
          <NotificationsProvider>
            <ApplicationProvider>
              <UserProvider>
                <ErrorBoundary>
                  {loaded && (
                    <Loader spinning={pageLoading}>
                      <GlobalStyles />

                      <Component {...pageProps} />
                    </Loader>
                  )}
                </ErrorBoundary>
              </UserProvider>
            </ApplicationProvider>
          </NotificationsProvider>
        </ServicesProvider>
      </ConfigProvider>
    </>
  );
};

export const getInitialProps = async (
  ctx,
): Promise<{ props: { user: IUser } }> => {
  return {
    props: {
      user: ctx.ctx?.req?.user,
    },
  };
};

App.getInitialProps = getInitialProps;

export default App;
