import cookie from 'js-cookie';

import { UI_TIME_OFFSET } from '../constants/cookies';

export default (): void => {
  cookie.set(UI_TIME_OFFSET, new Date().getTimezoneOffset(), {
    sameSite: 'None',
    secure: true,
  });
};
